<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner  m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center  auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto "
          v-if="!loading"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bem vindo ao GoTech CAS! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Faça seu login e inicie sua jornada
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Nome de usuário"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="username"
                    placeholder="johnwick"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Logar com LDAP
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
        <div v-else>
          <logo-loader-animation/>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import gql from 'graphql-tag'
import LogoLoaderAnimation from "@/components/misc/LogoLoaderAnimation.vue"

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    LogoLoaderAnimation,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loading:false
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.loading =true
          this.$apollo.query({
            query: gql`query($username: String, $password: String){
              process_cas_login(
                username : $username,
                password:$password
              ){
                uid
                message
                user_display_name
                role
                code
              }
            }`,
            variables:{
              username: this.username,
              password: this.password
            },
            client: "loginClient"
          }).then((data) => {
            this.returnObj = data.data.process_cas_login
            if(this.returnObj.message == "OK"){
              this.updateAbility(this.returnObj);
              this.$router.push('/')
            }
          }).catch((error) => {
            console.error(error)
          })
          this.loading =false
        }
      })
    },
    updateAbility(user) {
      const abilitiesArray = 
      [
        {
          title: 'admin',
            ability: [
            {
              action: 'manage',
              subject: 'all'
            },
            {
              action: 'be',
              subject: 'company-employee',
            },
          ]
        },
        {
          title: 'sales',
          ability:
          [ 
            {
              action: 'edit',
              subject: 'client-promotions',
            },
            {
              action: 'read',
              subject: 'client-promotions',
            },
            {
              action: 'edit',
              subject: 'client-groups',
            },
            {
              action: 'read',
              subject: 'client-groups',
            },
            {
              action: 'edit',
              subject: 'client',
            },
            {
              action: 'read',
              subject: 'client',
            },
            {
              action: 'read',
              subject: 'Auth',
            },
            {
              action: 'be',
              subject: 'company-employee',
            },
          ]
        },
        {
          title: 'moderator',
          ability:
          [ 
            {
              action: 'read',
              subject: 'client',
            },
            {
              action: 'read',
              subject: 'Auth',
            },
            {
              action: 'be',
              subject: 'company-employee',
            },
          ]
        },
        {
          title: 'chat_attendant',
          ability:
          [ 
            {
              action: 'read',
              subject: 'client-promotions',
            },
            {
              action: 'read',
              subject: 'client-groups',
            },
            {
              action: 'edit',
              subject: 'client',
            },
            {
              action: 'read',
              subject: 'client',
            },
            {
              action: 'read',
              subject: 'Auth',
            },
            {
              action: 'be',
              subject: 'company-employee',
            },
          ]
        },
        {
          title: 'master',
          ability:
          [ 
            {
              action: 'edit',
              subject: 'client',
            },
            {
              action: 'read',
              subject: 'client',
            },
            {
              action: 'read',
              subject: 'Auth',
            },
            {
              action: 'be',
              subject: 'company-partner',
            },
          ]
        }
      ]
      let currentAbility = abilitiesArray.find(abilitiesArray => abilitiesArray.title === user.role);
      let expires = new Date();
      expires.setDate(expires.getDate() + 2);
      let uData = {
        "uid": user.uid,
        "fullName": user.user_display_name,
        "username": this.username,
        "userRole": user.role,
        "inviteCode": user.code,
        "ability": currentAbility.ability,
        "expires": expires
      }
      window.localStorage.setItem('userData', JSON.stringify(uData));
      this.$ability.update(currentAbility.ability)
    }

  },
  created(){
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style>
  .brand-text{
    color:#fff!important;
    opacity: 1;
  }
</style>